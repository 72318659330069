import { render, staticRenderFns } from "./RegisterLogoAndMessage.vue?vue&type=template&id=26e48d54&"
import script from "./RegisterLogoAndMessage.vue?vue&type=script&lang=js&"
export * from "./RegisterLogoAndMessage.vue?vue&type=script&lang=js&"
import style0 from "./RegisterLogoAndMessage.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCard,VCol,VContainer,VFlex,VImg,VLayout,VRow,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/Users/imaiyuurika/sj_contract_procedure_contractor_front_mirai/admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('26e48d54')) {
      api.createRecord('26e48d54', component.options)
    } else {
      api.reload('26e48d54', component.options)
    }
    module.hot.accept("./RegisterLogoAndMessage.vue?vue&type=template&id=26e48d54&", function () {
      api.rerender('26e48d54', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/agency/RegisterLogoAndMessage.vue"
export default component.exports